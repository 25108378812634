import { Home, Store, YouCaring, ControlPanelRefresh, ControlPanelRedesign, Rebrand, QNAP, Anvil } from './pages';

export const routes = [
	Home,
	Store,
	YouCaring,
	ControlPanelRedesign,
	Rebrand,
	QNAP,
	ControlPanelRefresh,
	Anvil
];
