export const speakingEvents = [
	{
		title: 'Moving Design Forward Panel',
		url: 'https://www.eventbrite.com/e/moving-design-forward-tickets-52726869486',
		meta: '2018 Meetup'
	},
	{
		title: 'UI Design, Sass, & Front-end Frameworks',
		url: 'http://www.slideshare.net/MattFelten/dream-con-2013-49008170',
		meta: '2013 DreamCon'
	}
];
